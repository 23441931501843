import React, { FormEvent, useRef } from "react"
import { useTranslation } from 'react-i18next';

import { RowContainer, LabelTitle } from "./LabelTitle"

import { Button } from "../ui/button"
import { useStore } from "../../lib/states"
import { useClickAway, useToggle } from "react-use"
import { Textarea } from "../ui/textarea"
import { cn } from "../../lib/utils"

import { useHookStore } from "../../hook-store/store"

const DiffusionOptionTextPrompt = () => {
  const [
    isProcessing,
    prompt,
    updateSettings,
  ] = useStore((state) => [
    state.getIsProcessing(),
    state.settings.prompt,
    state.updateSettings,
    state.showPrevMask,
    state.hidePrevMask,
  ])

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useHookStore();
  const { 
    serverProcessing,
   } = (hStore as any).iopaintStore;
  const { 
    showToggleNav, 
    showInpaintSettings,
   } = (hStore as any).uiStore;

  const [showScroll, toggleShowScroll] = useToggle(false)

  const ref = useRef(null)
  useClickAway<MouseEvent>(ref, () => {
    if (ref?.current) {
      const input = ref.current as HTMLTextAreaElement
      input.blur()
    }
  })

  const handleOnInput = (evt: FormEvent<HTMLTextAreaElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    const target = evt.target as HTMLTextAreaElement
    updateSettings({ prompt: target.value })
  }


  return (
      <div 
      // className="flex flex-col gap-1"
      >
        <LabelTitle
          text="Text prompt input"
          // toolTip="Inpainting on part of image, improve inference speed and reduce memory usage. Cropper is enabled when larger size of image is used."
          toolTip={`${t('inpaintEditor07', "Describe what you want to draw... (accept English, please use translation for other languages")}`}
        />
        <Textarea
          ref={ref}
          // placeholder="Describe what you want to draw... (accept English, please use translation for other languages"
          placeholder={`${t('inpaintEditor07', "Describe what you want to draw... (accept English, please use translation for other languages")}`}
          className={cn(
            showScroll ? "focus:overflow-y-auto" : "overflow-y-hidden",
            // "min-h-[32px] h-[32px] overflow-x-hidden focus:h-[120px] overflow-y-hidden transition-[height] w-[500px] py-1 px-3 bg-background resize-none"
            "min-h-[32px] h-[32px] overflow-x-hidden focus:h-[120px] overflow-y-hidden transition-[height] py-1 px-3 bg-background resize-none"
          )}
          style={{
            scrollbarGutter: "stable", border: "0.5px solid gray"
          }}
          value={prompt}
          onInput={handleOnInput}
          // onKeyUp={onKeyUp}
          onTransitionEnd={toggleShowScroll}
        />
      </div>
  )
}

export default DiffusionOptionTextPrompt
