import { useCallback, useEffect, useRef, useState } from "react"

import useInputImage from "./hooks/useInputImage"
import { keepGUIAlive } from "./lib/utils"
import { getServerConfig } from "./lib/api"
import Header from "./components/Header"
import Workspace from "./components/Workspace"
import FileSelect from "./components/FileSelect"
import { Toaster } from "./components/ui/toaster.tsx"
import { useStore } from "./lib/states.ts"
import { useWindowSize } from "react-use"

import "inter-ui/inter.css"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ThemeProvider } from "next-themes"
import { TooltipProvider } from "./components/ui/tooltip.tsx"

import AboutIopaint from "./components/AboutContent/AboutIopaint.js"
import Backdrop from "./components/Backdrop/Backdrop.js"
import FileControl from "./components/FileControl/FileControl.js";
import LoadParamFile from "./components/FileControl/LoadParmFile.js"
import InpaintSettings from "./components/InpaintSettings/InpaintSettings.js"
import UploadImage from "./components/UploadImage/UploadImage.js"
import InpaintFiles from "./components/InpaintFiles/InpaintFiles.jsx"
// import Notification from "./components/Notification/Notification"
import { useHookStore } from "./hook-store/store";
import { API_ENDPOINT } from "./lib/api";

const queryClient = new QueryClient()

const SUPPORTED_FILE_TYPE = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/bmp",
  "image/tiff",
]
function Home() {
  const [file, updateAppState, setServerConfig, setFile] = useStore((state) => [
    state.file,
    state.updateAppState,
    state.setServerConfig,
    state.setFile,
  ])

  const storeData = useStore((state) => {
    console.log(state)
  })

  const [hStore, hDispatch] = useHookStore();
  console.log('hStore-in-iopaint.tsx', hStore);

  const { 
    notification, 
    showToggleNav,
   } = (hStore as any).uiStore;
  const { 
    showUploadImage,
    showAboutIopaint,
    showInpaintSettings,
    showInpaintFiles,
    isIopaintServerExist,
   } = (hStore as any).iopaintStore;

  // const [isServerExist, setIsServerExist] = useState(false);

  const userInputImage = useInputImage()

  const windowSize = useWindowSize()

  useEffect(() => {
    if (userInputImage) {
      setFile(userInputImage)
    }
  }, [userInputImage, setFile])

  useEffect(() => {
    updateAppState({ windowSize })
  }, [windowSize])

  useEffect(() => {
    const fetchServerConfig = async () => {
      const serverConfig = await getServerConfig()
      setServerConfig(serverConfig)
      if (serverConfig.isDesktop) {
        // Keeping GUI Window Open
        keepGUIAlive()
      }
    }
    fetchServerConfig()
  }, [])


  useEffect(() => {
    const fetchServer = async () => {
      try {
        const result = await fetch(API_ENDPOINT + '/model');
        
        const resData = await result.json();

        if (result.status !== 200) {
          throw new Error('fetch server failed');
        }

        if (result.status === 200) {
          // setIsServerExist(true);
          (hDispatch as any)('SET_ISIOPAINTSERVEREXIST', true);
          (hDispatch as any)('CLEAR_NOTIFICATION');

        } else {
          // setIsServerExist(false);
          (hDispatch as any)('SET_ISIOPAINTSERVEREXIST', false);
          throw new Error('fetch server failed');
        }

        // console.log(result, resData);

      } catch(err) {
        console.log('fetch server err', err);
        // setIsServerExist(false);
        (hDispatch as any)('SET_ISIOPAINTSERVEREXIST', false);

        (hDispatch as any)('SHOW_NOTIFICATION', {
          status: 'error',
          title: 'Service is not available now, Please try again later.',
          message: '',
        });
      }
    }

    fetchServer();

    const fetchServerInterval = setInterval(() => {
      fetchServer();
    }, 1000*5);

    return () => {
      clearInterval(fetchServerInterval);
      (hDispatch as any)('CLEAR_NOTIFICATION');
    }

  },[]);

  const dragCounter = useRef(0)

  const handleDrag = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  const handleDragIn = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    dragCounter.current += 1
  }, [])

  const handleDragOut = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    dragCounter.current -= 1
    if (dragCounter.current > 0) return
  }, [])

  const handleDrop = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      if (event.dataTransfer.files.length > 1) {
        // setToastState({
        //   open: true,
        //   desc: "Please drag and drop only one file",
        //   state: "error",
        //   duration: 3000,
        // })
      } else {
        const dragFile = event.dataTransfer.files[0]
        const fileType = dragFile.type
        if (SUPPORTED_FILE_TYPE.includes(fileType)) {
          setFile(dragFile)
        } else {
          // setToastState({
          //   open: true,
          //   desc: "Please drag and drop an image file",
          //   state: "error",
          //   duration: 3000,
          // })
        }
      }
      event.dataTransfer.clearData()
    }
  }, [])

  const onPaste = useCallback((event: any) => {
    // TODO: when sd side panel open, ctrl+v not work
    // https://htmldom.dev/paste-an-image-from-the-clipboard/
    if (!event.clipboardData) {
      return
    }
    const clipboardItems = event.clipboardData.items
    const items: DataTransferItem[] = [].slice
      .call(clipboardItems)
      .filter((item: DataTransferItem) => {
        // Filter the image items only
        return item.type.indexOf("image") !== -1
      })

    if (items.length === 0) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    // TODO: add confirm dialog

    const item = items[0]
    // Get the blob of image
    const blob = item.getAsFile()
    if (blob) {
      setFile(blob)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("dragenter", handleDragIn)
    window.addEventListener("dragleave", handleDragOut)
    window.addEventListener("dragover", handleDrag)
    window.addEventListener("drop", handleDrop)
    window.addEventListener("paste", onPaste)
    return function cleanUp() {
      window.removeEventListener("dragenter", handleDragIn)
      window.removeEventListener("dragleave", handleDragOut)
      window.removeEventListener("dragover", handleDrag)
      window.removeEventListener("drop", handleDrop)
      window.removeEventListener("paste", onPaste)
    }
  })

  return (
  // <QueryClientProvider client={queryClient}>
    // <ThemeProvider defaultTheme="dark" disableTransitionOnChange>
      // <TooltipProvider>
        <main 
          className="flex min-h-screen flex-col items-center justify-between w-full bg-[radial-gradient(circle_at_1px_1px,_#8e8e8e8e_1px,_transparent_0)] [background-size:20px_20px] bg-repeat"
          style={{overflow: "hidden"}}
        >
          <Toaster />
          <Header />
          <Workspace />
          {!file ? (
            <FileSelect
              onSelection={async (f) => {
                setFile(f)
              }}
            />
          ) : (
            <></>
          )}

          {/* {notification && (
            <div>
              <Notification 
                status={notification.status}
                title={notification.title}
                message={notification.message}
              />
            </div>
          )} */}

          {showUploadImage && (
            <div>
              <UploadImage />
            </div>
          )}

          {showToggleNav && (
            <Backdrop 
              zIndex={'10'}
              // backgroundColor={'rgba(0, 0, 0, 0.1)'}
            //  backgroundColor={'rgba(0, 0, 0, 0)'}
              onCancel={() => {
                (hDispatch as any)('SET_SHOWTOGGLENAV', false); 
              }}
            />
          )}

          {showAboutIopaint && (
            <AboutIopaint />
          )}

          {showInpaintSettings && (
            <InpaintSettings />
          )}

          {showInpaintFiles && (
            <InpaintFiles />
          )}

          <FileControl />
          <LoadParamFile />
        </main>
      // </TooltipProvider>
    // </ThemeProvider>
  // </QueryClientProvider>
  )
}

export default Home
