import React, { FormEvent, useRef } from "react"
import { useTranslation } from 'react-i18next';

import { Button } from "./ui/button"
import { useStore } from "../lib/states"
import { useClickAway, useToggle } from "react-use"
import { Textarea } from "./ui/textarea"
import { cn } from "../lib/utils"

import { useHookStore } from "../hook-store/store"

const PromptInput = () => {
  const [
    isProcessing,
    prompt,
    updateSettings,
    runInpainting,
    showPrevMask,
    hidePrevMask,
  ] = useStore((state) => [
    state.getIsProcessing(),
    state.settings.prompt,
    state.updateSettings,
    state.runInpainting,
    state.showPrevMask,
    state.hidePrevMask,
  ])

  const [t] = useTranslation('translation');

  const [hStore, hDispatch] = useHookStore();
  const { 
    serverProcessing,
    isIopaintServerExist,
   } = (hStore as any).iopaintStore;
  const { 
    showToggleNav, 
    showInpaintSettings,
   } = (hStore as any).uiStore;

  const [showScroll, toggleShowScroll] = useToggle(false)

  const ref = useRef(null)
  useClickAway<MouseEvent>(ref, () => {
    if (ref?.current) {
      const input = ref.current as HTMLTextAreaElement
      input.blur()
    }
  })

  const handleOnInput = (evt: FormEvent<HTMLTextAreaElement>) => {
    evt.preventDefault()
    evt.stopPropagation()
    const target = evt.target as HTMLTextAreaElement
    updateSettings({ prompt: target.value })
  }

  const handleRepaintClick = () => {
    if (!isProcessing) {
      runInpainting()
    }
  }

  const onKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && e.ctrlKey && prompt.length !== 0) {
      handleRepaintClick()
    }
  }

  const onMouseEnter = () => {
    showPrevMask()
  }

  const onMouseLeave = () => {
    hidePrevMask()
  }


  return (
    <div className="flex gap-4 relative w-full justify-center h-full">
      <div className="absolute flex gap-4">
        {/* <Textarea
          ref={ref}
          // placeholder="Describe what you want to draw... (accept English, please use translation for other languages"
          placeholder={`${t('inpaintEditor07', "Describe what you want to draw... (accept English, please use translation for other languages")}`}
          className={cn(
            showScroll ? "focus:overflow-y-auto" : "overflow-y-hidden",
            // "min-h-[32px] h-[32px] overflow-x-hidden focus:h-[120px] overflow-y-hidden transition-[height] w-[500px] py-1 px-3 bg-background resize-none"
            "min-h-[32px] h-[32px] overflow-x-hidden focus:h-[120px] overflow-y-hidden transition-[height] py-1 px-3 bg-background resize-none"
          )}
          style={{
            scrollbarGutter: "stable",
          }}
          value={prompt}
          onInput={handleOnInput}
          // onKeyUp={onKeyUp}
          onTransitionEnd={toggleShowScroll}
        /> */}
        <Button
          size="sm"
          // onClick={handleRepaintClick}
          disabled={isProcessing || serverProcessing || showToggleNav || showInpaintSettings || !prompt || !isIopaintServerExist}
          // onMouseEnter={onMouseEnter}
          // onMouseLeave={onMouseLeave}
          onClick={() => {
            (hDispatch as any)('SET_SHOWTOGGLENAV', false);
            (hDispatch as any)('SET_SHOWINPAINTSETTINGS', true);
          }}
        >
          {!(serverProcessing || isProcessing) && (
            <span>Paint</span>
          )}
          {(serverProcessing || isProcessing) && (
            <span>busy...</span>
          )}
          {/* Paint */}
        </Button>
      </div>
    </div>
  )
}

export default PromptInput
